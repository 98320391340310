.label{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    font-weight: 500;
    color:#515151;
    margin: 5px 0;
    line-height: 17px;
}

.incorrectInput{
    border : 1px solid red;
}

.stepper{
    padding:24px 0 ;
}

.mainCont{
    background: repeating-linear-gradient(#FFF3ED, #FFF3ED 50.1%, #FFF 49.9%, #FFF 100%);
    overflow-x: hidden;
}

.perferencestyle{
    font-size: 14px;
    color:'#6C6866';
    margin: 10px 9%;
}