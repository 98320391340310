.message-container {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 20px;
}

.leftMessagePeople {
	width: 381px;
	height: 75vh;
	box-shadow: 10px 2px 10px rgba(134, 150, 166, 0.19);
	border-radius: 8px;
	overflow-y: auto;
}

.leftMessagePeople::-webkit-scrollbar {
	width: 10px;
	border-radius: 10px;
}

.leftMessagePeople::-webkit-scrollbar-thumb {
	background: linear-gradient(
		109.78deg,
		rgb(255, 207, 32) -15.8%,
		rgb(253, 128, 18) 111.31%
	);
	border-radius: 10px;
}
.rightMessage {
	width: 918px;
	height: 75vh;
	box-shadow: 10px 2px 10px rgba(134, 150, 166, 0.19);
	border-radius: 8px;
}

.leftNotid {
	font-size: 16px;
	line-height: 24px;
	color: #808080;
	background: #f1f1f1;
	border-radius: 87px;
	margin: 20px 0px 20px 0px;
	text-align: center;
	padding: 5px 0px;
	box-sizing: border-box;
	width: 50%;
	margin: 0;
	cursor: pointer;
}

.mainControl{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 36px;
	margin: 20px 5px 20px 5px;
	padding: 10px;
	color: #808080;
	background: #f1f1f1;
	border-radius: 87px;
	padding: 0;
}

.css-g1d714-ValueContainer{
	padding-left: 14px;
}

.activeMessagePart{
	color: white;
	z-index: 101;
	background-color: #32A575;
}

.css-yk16xz-control{
	border-radius: 50px;
}

.switchMessage {
	margin: 24px;
	margin-top: 0;
	display: inline-flex;
	align-items: center;
	gap: 15px;
}

.messageProfileCont {
	padding: 16px 24px;
	width: 100%;
	display: inline-flex;
	align-items: center;
	gap: 15px;
	cursor: pointer;
}

.messageProfileCont > img {
	width: 40px;
	height: 40px;
	object-fit: cover;
	border-radius: 50%;
}

.activeMessageProf {
	background: #edf9f4;
	box-shadow: inset -4px 0px 0px #47bc96;
}

.profName {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */
	color: #0b0b0b;
}

.profLocation {
	font-size: 14px;
	line-height: 21px;
	height: 21px;
	width: 265px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: #758482;
}

.messageRightTop {
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0px 1px 0px #d4d4d4;
	padding-right: 24px;
}

.sendMsgCont {
	width: 100%;
	padding: 15px 25px;
	display: inline-flex;
	gap: 16px;
	align-items: center;
	box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.25);
	overflow: auto;
}

.sendMsgBtn {
	border: none;
	padding: 14px 33px;
	display: inline-flex;
	align-items: center;
	gap: 10px;
	background: linear-gradient(109.78deg, #ffcf20 -15.8%, #fd8012 111.31%);
	border-radius: 79px;
	cursor: pointer;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */
	text-align: center;
	/* Neutral / Gray 50 */
	color: #f8fafc;
}

.sendMsgInpt {
	border: 1px solid #d4d4d4;
	border-radius: 68px;
	width: 100%;
	padding: 13px 24px;
}

.messageCont {
	height: calc(100% - 160px);
	padding: 26px;
	overflow-y: auto;
}

.messageContMsg {
	display: flex;
	flex-direction: column;
	margin: 10px 0;
}

.messageRight {
	align-items: flex-end;
}

.messageLeft {
	align-items: flex-start;
}

.message {
	border: 1px solid #c6d1e2;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 10px;
	margin-top: 10px;
	max-width: 70%;
	display: inline-block;
	overflow: hidden;
	white-space: pre-line;
	word-break: break-all;
}

@media screen and (max-width: 960px) {
	.message {
		max-width: 80%;
	}
}

.timeContMsg {
	display: flex;
	gap: 10px;
	justify-content: end;
	align-items: center;
	font-size: 12px;
	line-height: 19px;
	/* identical to box height */
	/* Black Neutral/400 */
	color: #808080;
}

.leftMessagePeople, .rightMessage{
	box-shadow: 0 0 20px 0px rgb(134 150 166 / 19%);
		border-radius: 8px;
		border: #cacaca54 solid 1px;
	}

.leftMessagePeople{
	overflow-x: hidden;
}
.profName{
	font-weight: 600
}
.messageProfileCont{
	border: 1px #0000000f solid;
}