.videoRecorderContainer {
	width: 95%;
	margin: auto;
	margin-top: 50px;
	max-width: 1550px;
}

.vidRecordTitle {
	font-family: 'Work Sans';
	font-weight: 600;
	font-size: 28px;
	line-height: 34px;
	letter-spacing: -0.03em;
	color: #0b0b0b;
	margin-bottom: 11px;
	text-align: center;
}

.vidRecordSubTitle {
	font-family: 'Work Sans';
	font-size: 19px;
	line-height: 23px;
	text-align: center;
	letter-spacing: -0.04em;
	color: #2a2a2a;
	margin-bottom: 40px;
}

.recorderContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.recorderLeft {
	width: 45%;
	max-width: 662px;
	box-sizing: border-box;
	padding-left: 30px;
	padding-right: 50px;
	max-height: 750px;
	overflow: auto;
	margin-top: -25px;
}

.transcriptTitle {
	font-family: 'Work Sans';
	font-weight: 600;
	font-size: 23px;
	line-height: 28px;
	letter-spacing: -0.03em;
	color: #2a2a2a;
	margin: 20px 0;
	margin-top: 0;
}

.transcript {
	font-family: 'Open Sans';
	font-size: 16px;
	line-height: 24px;
	color: #2a2a2a;
}

.recordRight {
	width: 55%;
}

@media screen and (max-width: 1000px) {
	.recorderContainer {
		flex-direction: column-reverse;
	}
	.recorderLeft,
	.recordRight {
		width: 100%;
	}
	.recorderLeft {
		margin-top: 50px;
	}
}

.videoCont {
	min-height: 500px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.reqestPermissionCont {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.reqPermissonBtn {
	margin-top: 40px;
	background: #0667c3;
	border-radius: 50px;
	color: #ffffff;
	cursor: pointer;
	padding: 12px 18px;
	border: none;
	outline: none;
}

.vidPlayer {
	width: 100%;
	border-radius: 13px;
}

.controlsCont {
	display: flex;
	justify-content: center;
	align-items: center;
}

.fileUploadInitial {
	width: 173px;
	height: 52px;
	background: #ffffff;
	border: 1px solid #0667c3;
	box-sizing: border-box;
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.07);
	border-radius: 29px;
	display: inline-flex;
	gap: 10px;
	padding: 15px 18px;
	font-family: 'Open Sans';
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	color: #0667c3;
	cursor: pointer;
}

.fileUploadInitial:hover {
	background-color: #0667c3;
	color: white;
}

.uploadIconWht {
	display: none;
}

.fileUploadInitial:hover > .uploadIconBlu {
	display: none;
}

.fileUploadInitial:hover > .uploadIconWht {
	display: block;
}

.recorderOptions {
	margin-top: 12px;
	position: relative;
}

.recordTextC2A {
	margin-bottom: 10px;
	font-family: 'Open Sans';
	font-size: 16px;
	line-height: 24px;
	color: #7f8c9e;
	text-align: center;
}

.startRecording {
	margin: auto;
	box-sizing: border-box;
	width: 85px;
	height: 85px;
	border-radius: 50%;
	background: #ffffff;
	box-shadow: 0px 1.65049px 21px rgba(0, 0, 0, 0.18);
	padding: 8px;
	cursor: pointer;
}

.redCircle {
	background: #dc404e;
	border-radius: 50%;
	width: 100%;
	height: 100%;
}

.squareFlex {
	display: flex;
	align-items: center;
	justify-content: center;
}

.redSquare {
	background: #dc404e;
	border-radius: 5px;
	width: 30px;
	height: 30px;
}

.finalControls {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 25px;
	width: 100%;
	margin-top: 20px;
}

.crossSelect {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 75px;
	height: 75px;
	background: #ffffff;
	box-shadow: 0px 1.65049px 21px rgba(0, 0, 0, 0.18);
	border-radius: 50%;
	cursor: pointer;
}

.previewCont {
	width: 95%;
	max-width: 864px;
	margin: auto;
	margin-top: 50px;
}

.previewVid {
	width: 100%;
	margin: 50px auto;
	border-radius: 13px;
}

.previewControlsCont {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.previewControlRetry {
	box-sizing: border-box;
	display: inline-flex;
	gap: 16px;
	cursor: pointer;
	padding: 14px 40px;
	width: 18.4%;
}

.previewControlAccept {
	text-align: center;
	padding: 14px;
	font-family: 'Open Sans';
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	width: 76.84%;
	background: #0667c3;
	box-shadow: 0px 5px 21px #9fb8cf;
	border-radius: 29px;
	cursor: pointer;
}

.uploadSec {
	position: absolute;
	right: 0;
	top: 45%;
}

.fileUploadSec {
	padding: 9.5px 11px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	background: #ffffff;
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
	border-radius: 29px;
	font-family: 'Roboto';
	font-size: 14px;
	line-height: 16px;
	color: #000000;
	cursor: pointer;
}

@media screen and (max-width: 960px) {
	.videoCont {
		min-height: 400px;
	}
	.uploadSec {
		position: relative;
		display: block;
		margin: auto;
		margin-top: 30px;
		width: fit-content;
	}
	.previewControlsCont {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 40px;
	}
	.previewControlRetry {
		width: unset;
	}
}
