.separator {
	display: flex;
	align-items: center;
	text-align: center;
	color: #9d9d9d;
	font-size: 0.9em;
	font-weight: 500;
	font-family: 'Montserrat';
}

.separator::before,
.separator::after {
	content: '';
	flex: 1;
	border-bottom: 1px solid #c4c3c3;
}

.separator:not(:empty)::before {
	margin-right: 0.25em;
}

.separator:not(:empty)::after {
	margin-left: 0.25em;
}

.text {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
}

.label {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	font-weight: 500;
	color: #515151;
	margin: 5px 0;
	line-height: 17px;
}

.tkCont {
	width: 332px;
	height: 227px;
}

.tkHeading {
	font-weight: 600;
	font-size: 23px;
	line-height: 28px;
	/* identical to box height, or 122% */

	letter-spacing: -0.04em;

	color: #0f172a;
	margin-bottom: 11px;
}

.tkSub {
	font-size: 16px;
	line-height: 28px;
	/* or 175% */

	letter-spacing: -0.01em;

	color: #0f172a;
}
