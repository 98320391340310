.attachmentHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px;
	margin: 50px auto;
}

.attachmentHeading {
	font-size: 23px;
	line-height: 28px;
	/* identical to box height, or 122% */

	letter-spacing: -0.03em;

	color: #59534d;
}

.uploadBTN {
	cursor: pointer;
	border: 1px solid #0667c3;
	box-sizing: border-box;
	border-radius: 50px;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height, or 121% */

	text-align: center;

	color: #0667c3;
	padding: 12px 25px;
}

.userRolesCont {
	margin: auto;
	width: 1000px;
	max-width: 95vw;
	margin-top: 50px;
	border: 1px solid #c3d2df;
	box-sizing: border-box;
	border-radius: 11px;
	padding: 5px;
}

.tableCont {
	margin: auto;
	width: 100%;
	overflow-x: auto;
}

.searchBarCont {
	width: 95%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 50px auto;
	margin-top: 10px;
}

.searchBarCont > button {
	border: none;
	cursor: pointer;
	background: linear-gradient(109.78deg, #ffcf20 -15.8%, #fd8012 111.31%),
		#f47b08;
	border-radius: 50px;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height, or 121% */
	text-align: center;
	/* Neutral / Gray 50 */
	color: #f8fafc;
	padding: 12px 18px;
}

.tableLayout2 {
	box-sizing: border-box;
	min-width: 950px;
	padding: 8px 10px;
	display: grid;
	grid-template-columns: 1fr 4fr 3fr;
}

.tableHead {
	font-size: 14px;
	line-height: 19px;
	/* identical to box height */

	text-transform: uppercase;

	color: #8c96a6;
}

.docTableCont2 {
	margin-top: 13px;
	box-sizing: border-box;
	background: #e7ecf3;
	border-radius: 6px;
}

.docRow {
	background-color: #fff;
	margin-bottom: 1px;
	width: 100%;
	padding: 10px;
}

.docRowFirst {
	border-top-left-radius: 11px;
	border-top-right-radius: 11px;
}

.docRowLast {
	border-bottom-left-radius: 11px;
	border-bottom-right-radius: 11px;
}

.docName {
	display: inline-flex;
	align-items: center;
	gap: 16px;
	font-size: 14px;
	line-height: 19px;
	/* identical to box height */

	color: #282d35;
}

.docDate {
	font-size: 15px;
	line-height: 19px;
	color: #282d35;
}

.docName > img {
	cursor: pointer;
}

.docAction {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.visibilityChanger {
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	gap: 16px;
}

.IconCont {
	display: inline-flex;
	align-items: center;
	gap: 20px;
}

.IconCont > img {
	cursor: pointer;
}

.modalCont {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	outline: none;
	box-sizing: border-box;
	box-shadow: 0px 0px 4px rgba(122, 102, 92, 0.26),
		0px 4px 22px rgba(122, 102, 92, 0.22);
	border-radius: 8px;
	padding: 1rem;
	overflow: auto;
	max-height: 80vh;
	width: 625px;
	max-width: 95vw;
}

.modalHeadinFileUp {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 30px;
	flex-wrap: wrap;
	margin-bottom: 40px;
}

.fileUPC2a {
	border: none;
	background: linear-gradient(109.78deg, #ffcf20 -15.8%, #fd8012 111.31%);
	border-radius: 43px;
	padding: 10px 20px;
	box-sizing: border-box;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height, or 121% */

	text-align: center;

	color: #ffffff;
}

.FileInputDesign {
	border: 2px dashed #8da1ad;
	box-sizing: border-box;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 24px;
	position: relative;
	z-index: 1;
}

#fileUp {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	width: 100%;
	overflow: hidden;
	position: absolute;
	z-index: 2;
	box-sizing: border-box;
}

.teamContainer {
	box-sizing: border-box;
	width: 100%;
	margin-top: 3em;
	border: 1px solid #c3d2df;
	background: #fff;
	border-radius: 8px;
}

.teamHeader {
	padding: 1em;
	gap: 10px;
	display: flex;
}

.actions {
	display: flex;
	gap: 10px;
	padding: 1em;
	flex-direction: row;
}

.actionBtn {
	border: none;
	background-color: transparent;
}

.membersGrid {
	height: 600px;
	width: 100%;
	margin: auto;
}

.commsPrefsCont {
	padding: 1rem;
	margin-top: 50px;
	background: #ffffff;
	border: 1px solid #c3d2df;
	box-sizing: border-box;
	border-radius: 11px;
}

.inputContCom {
	display: inline-flex;
	gap: 10px;
	flex-wrap: wrap;
	margin-top: 40px;
	margin-bottom: 40px;
}

.inputContCom > input {
	border: 1px solid #cbd5e1;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 1rem;
	width: 430px;
}

.inputContCom > button {
	padding: 17px 20px;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height, or 121% */
	text-align: center;
	/* Neutral / Gray 50 */
	color: #f8fafc;
	background: linear-gradient(109.78deg, #ffcf20 -15.8%, #fd8012 111.31%),
		#f47b08;
	border-radius: 50px;
	border: none;
}

.BtnCOnt > button {
	padding: 17px 20px;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height, or 121% */
	text-align: center;
	/* Neutral / Gray 50 */
	color: #f8fafc;
	background: linear-gradient(109.78deg, #ffcf20 -15.8%, #fd8012 111.31%),
		#f47b08;
	border-radius: 50px;
	border: none;
}

.comPrefTable {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 95%;
	margin: 20px auto;
}

.comPref1 {
	padding: 5px 10px;
	max-width: 800px;
}

.comPref2 {
	padding: 5px 10px;
	width: 150px;
	display: inline-flex;
	align-items: center;
	gap: 25px;
}

.comPrefTitle {
	font-size: 14px;
	line-height: 19px;
	/* identical to box height */
	text-transform: uppercase;
	color: #8c96a6;
}

.searchLabelU {
	font-size: 14px;
	line-height: 17px;
	color: #475569;
	margin-top: 15px;
	margin-bottom: 5px;
}

.BtnCOnt {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 50px;
}

.BtnCOntLeft {
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	/* Primary Color/500 */
	color: #f47b08;
	cursor: pointer;
}

.tableOverflow {
	width: 100%;
	min-width: 1110px;
	overflow-x: scroll;
	border: 1px solid #e5ebf4;
	box-sizing: border-box;
	border-radius: 8px;
	border-collapse: collapse;
}

.tableHeads {
	font-size: 14px;
	line-height: 19px;
	/* identical to box height */
	text-transform: uppercase;
	color: #8c96a6;
	padding: 5px;
}

.deleTabLayout {
	width: 100%;
	display: grid;
	grid-template-columns: 40px 0.8fr 1.2fr 1.2fr 1fr 0.6fr;
	gap: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	border: 1px solid #e5ebf4;
	padding: 5px;
	border-collapse: collapse;
}

.deleTabLayout2 {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1.3fr 1fr 1fr 0.5fr 1fr;
	gap: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	border: 1px solid #e5ebf4;
	padding: 5px;
	border-collapse: collapse;
}

.deleTabLayout > div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.deleTabLayout2 > div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.bulkTransfer {
	display: inline-flex;
	gap: 35px;
	align-items: center;
	margin: 34px 0;
}

.bulkTransfer > button {
	border: none;
	background: #0667c3;
	border-radius: 50px;
	padding: 12px 18px;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height, or 121% */
	text-align: center;
	letter-spacing: -0.02em;
	/* Neutral / Gray 50 */
	color: #f8fafc;
}

.posIndicator {
	display: flex;
	width: 100%;
	align-items: center;
	margin-top: 15px;
	margin-bottom: 15px;
}

.indicator {
	display: inline-flex;
	gap: 10px;
	align-items: center;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height, or 121% */
	letter-spacing: -0.02em;
	color: #c4c4c4;
	white-space: nowrap;
}

.indicatorNum {
	padding: 10px 15px;
	border-radius: 50%;
	border: 1px solid #c4c4c4;
}

.activeIndicator {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height, or 121% */
	letter-spacing: -0.02em;
	color: #212121;
}

.activeIndicator > .indicatorNum {
	color: #ffffff !important;
	background: #0667c3;
	border-color: #0667c3 !important;
}

.line {
	width: 100%;
	height: 1px;
	background: #c4c4c4;
}

@media screen and (max-width: 960px) {
	.posIndicator {
		display: block;
	}
	.circle {
		width: 80px;
		height: 80px;
	}
	.circle > img {
		border-radius: 50%;
		width: 70px;
		height: 70px;
		object-fit: cover;
	}
}

.fourGridCol {
	display: grid;
	gap: 25px;
	grid-template-columns: repeat(auto-fit, 200px);
}

.firstColHead {
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	text-transform: uppercase;
	color: #999999;
	width: 200px;
}

.colData {
	font-size: 14px;
	color: #606060;
	width: 200px;
}

.graphColDiv {
	display: flex;
	gap: 25px;
	flex-wrap: wrap;
}

.bardGraphContDiv {
	width: 330px;
}

.bardGraphCont {
	width: 330px;
	position: relative;
	padding: 12px;
	background: #f3f3f3;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 5px;
}

.barLabel {
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */
	letter-spacing: -0.04em;
	color: #606060;
	z-index: 10;
}

.barPercent {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */
	color: #060606;
	z-index: 10;
}

.barColor {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	background-color: #98D5B3;
	z-index: 1;
}

.modalTeamData {
	border: 1px solid #B5B5B5;
	border-radius: 5px;
	margin: 30px auto;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 35px;
	padding: 6px 16px;
	align-items: center;
}

.modalTeamData > div {
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	/* or 171% */
	color: #222222;
}

.circle {
	position: relative;
	width: 100px;
	height: 100px;
}

.circle > img {
	border-radius: 50%;
	width: 90px;
	height: 90px;
	object-fit: cover;
}
