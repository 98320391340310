.notificationDD {
	padding: 20px;
	box-sizing: border-box;
	width: 360px;
}

.notiDDHeader {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.notiDDTitle {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */

	/* Neutral/900 */

	color: #181818;
}

.notiDDsubTitle {
	font-size: 14px;
	line-height: 19px;
	/* identical to box height */

	text-align: right;

	/* Black Neutral/300 */

	color: #9c9c9c;
	cursor: pointer;
}

.notiC2a {
	margin-top: 12px;
	font-size: 14px;
	line-height: 19px;
	/* identical to box height */

	text-align: center;

	/* Neutral/900 */

	color: #181818;
	cursor: pointer;
}

.notification {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 12px;
	padding: 16px 0;
	box-sizing: border-box;
	width: 100%;
	border-top: 1px solid #dfe8f4;
}

.notiImg {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.notiBodyHead {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;
}

.notiName {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */

	/* Neutral/900 */

	color: #181818;
}

.notiTime {
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.02em;

	/* Black Neutral/300 */

	color: #9c9c9c;
}

.notiDesc {
	font-size: 14px;
	line-height: 19px;

	/* Neutral/500 */

	color: #636363;
}


.navbar-toggler {
	border: white;
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

.navbar-toggler .crossBtn {
	background-image: url('./cross.svg');
	display:inline-block;
	vertical-align:middle;
	width: 30px;
	border-radius: 50%;
}

.profileDiv {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 14px 8px 14px 14px;

	margin-top: 15px;
	width: 100%;
	height: 82px;

	background: rgba(236, 239, 243, 0.66);
	border-radius: 12px;
}

.arrow {
	position: static;
	width: 14px;
	height: 9.33px;
	left: 313px;
	top: 19.33px;

	border: 1.4px solid #344452;

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 10px;
}
