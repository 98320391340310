.EmpDashCont {
	padding: 1em;
	padding-top: 3em;
	box-sizing: border-box;
	width: 100%;
	box-shadow: 0px 0px 1px rgba(118, 129, 146, 0.69),
		0px 5px 7px rgba(164, 186, 207, 0.19);
	border-radius: 8px;
}

.SearchJobCard {
	border: 1px solid #d0d8e4;
	border-radius: 8px;
	padding: 1.5em;
	box-sizing: border-box;
}

.statsBadge {
	display: inline-flex;
	justify-content: center;
	gap: 15px;
	align-items: center;
}

.stat {
	font-size: 24.9634px;
	line-height: 30px;
	letter-spacing: -0.03em;
	color: #000000;
}

.statName {
	font-size: 15.1951px;
	line-height: 19px;
	color: #474747;
}

.cardTitleCont {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 20px;
	gap: 10px;
}

.cardTitleBlock {
	width: 613px;
	/* padding-top: 1.5em; */
}

.cardTitle {
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: #212121;
	margin-bottom: 5px;
}

.cardSchool {
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.03em;
	color: #4d4d4d;
}

.cardStatsCont {
	display: inline-flex;
	gap: 25px;
	flex-wrap: wrap;
}

.cardDraft {
	background: #0667c3;
	border-radius: 50px;
	padding: 14px 39px;
	border: none;
	cursor: pointer;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #f8fafc;
}

.cardBottomCont {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.cardBottomInfo {
	width: 155px;
}

.cardBottomPosted {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #a6a6a6;
}

.cardBottomExpire {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #4d4d4d;
	margin-top: 5px;
}

.cardBottomC2a {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 264px;
}

.c2aIcon {
	padding: 10px 12px;
	display: inline-flex;
	align-items: center;
	gap: 6px;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #0667c3;
	cursor: pointer;
}

.activeStats {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: 10px;
	margin-bottom: 50px;
}

.postedJobCard {
	border-radius: 8px;
	background-color: #E1F0FF;
	padding: 1.5em;
}

.postedTitle {
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	color: #161D26;
	margin-bottom: 5px;
}

.postedSubtitle {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #636363;
}

.postedTags {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 10px;
	width: 100%;
}

.profileTag {
	padding: 9.2px 12px;
	border: 1px solid #e4e8f1;
	box-sizing: border-box;
	border-radius: 50px;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.02em;
	color: #33334e;
	display: inline-flex;
	gap: 10px;
	justify-content: center;
}

.salaryTag {
	display: inline-block;
	gap: 3px;
	justify-content: center;
}

.aboutTitle {
	font-weight: 800;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 5px;
}

.aboutDesc {
	font-size: 14px;
	color: #636363;
}

.headerBtn:hover {
	background: #0667C3 !important;
	color: #fff !important;
}

.cardHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	min-width: 90%;
	gap: 10px;
}

.cardActions {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	flex-wrap: wrap;
	min-width: 40%;
	gap: 10px;
}

.featuredCarousel {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	max-height: 25rem;
	gap: 10px;
}

.featuredCandidate {
	border: 1px solid #d0d8e4;
	border-radius: 8px;
	padding: 1.5em;
	box-sizing: border-box;
	display: inline-block;
	margin: 0.2em;
	height: 90%;

	width: 100%;
	/* min-width: 300px; */
}

.recommendedCandidate {
	border: 1px solid #d0d8e4;
	border-radius: 8px;
	padding: 1.5em;
	box-sizing: border-box;
	display: inline-block;
	margin: 0.2em;
	height: 90%;

	width: 100%;
}

.contactDiv {
	display: flex;
	justify-content: right;
	padding-bottom: 1em;
}

.featuredCandidatesL {
	padding-left: 10vh;
	padding-right: 10vh;
}

.featuredCandidatesP {
	padding-left: 10vw;
	padding-right: 10vw;
}

.recommendedCandidatesL {
	padding-left: 10vh;
	padding-right: 10vh;
}

.recommendedCandidatesP {
	padding-left: 10vw;
	padding-right: 10vw;
}

.featuredCarousel {
	align-items: center;
}

.profileImg {
	width: 80px;
	height: 80px;
	border-radius: 50%;
}

@media (max-width: 464px) {
	.recommendedCandidate {
		width: 100% !important;
	}

	.profileImg {
		width: 60px;
		height: 60px;

	}

	.postedTags {
		gap: 5px;
		font-size: 10px;
	}
}

.firstDashCard {
	width: 100%;
	margin: 20px auto;
	box-sizing: border-box;
	border: 1px solid #E0E0E0;
	box-shadow: 0px 5px 9px rgba(226, 226, 226, 0.2);
	border-radius: 10px;
	padding: 2rem;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 40px;
}

.cardIntro {
	padding: 8px 12px;
	height: 33px;
	text-align: center;
	border: 1px solid #E9E9E9;
	border-radius: 57px;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #414853;
}

.copyToClipBoardCont {
	padding: 8px 18px;
	box-sizing: border-box;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	width: 355px;
	max-width: 100%;
	border: 0.5px solid #D5DEDE;
	border-radius: 4px;
	cursor: pointer;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: -0.02em;
	color: #0570D2;
}

.skipBtn {
	text-transform: none;
	font-size: 14px;
	background: linear-gradient(109.78deg, rgb(255, 207, 32) -15.8%, rgb(253, 128, 18) 111.31%);
	color: rgb(255, 255, 255);
	font-weight: 600;
	border-radius: 25px;
	padding: 10px 100px;
	cursor: pointer;
	border: none;
}
