
.rating-page-content{
    border: 1px solid #E4E4E4;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.11);
    border-radius: 10px; 
}
.rating-giving-div {
    border: 1px solid #E4E4E4;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
    background-color: white;
}
.rating-data{
    border-bottom: 1px solid #E4E4E4;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.11);

}

@media screen and (max-width: 768px) {
    .rating-page-container{
        width: 90vw;
        margin: 0 auto;
    }
}
    
@media screen and (max-width: 576px) {
    .rating-page-container{
        width: 100vw;
        /* margin: 0 auto; */
    }
}
    
