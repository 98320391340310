.EmpDashCont {
	padding: 1em;
	padding-top: 3em;
	box-sizing: border-box;
	width: 100%;
	box-shadow: 0px 0px 1px rgba(118, 129, 146, 0.69),
		0px 5px 7px rgba(164, 186, 207, 0.19);
	border-radius: 8px;
}

.EmpDashJobCard {
	border: 1px solid #d0d8e4;
	border-radius: 8px;
	padding: 1.5em;
	box-sizing: border-box;
}

.statsBadge {
	display: inline-flex;
	justify-content: center;
	gap: 15px;
	align-items: center;
}
.incorrectInput{
    border : 1px solid red;
	border-radius: 6px;
}


.stat {
	font-size: 24.9634px;
	line-height: 30px;
	letter-spacing: -0.03em;
	color: #000000;
}

.statName {
	font-size: 15.1951px;
	line-height: 19px;
	color: #474747;
}

.cardTitleCont {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 20px;
	gap: 10px;
}

.cardTitleBlock {
	width: 613px;
}

.cardTitle {
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: #212121;
	margin-bottom: 5px;
}

.cardSchool {
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.03em;
	color: #4d4d4d;
}

.cardStatsCont {
	display: inline-flex;
	gap: 25px;
	flex-wrap: wrap;
}

.cardDraft {
	background: #0667c3;
	border-radius: 50px;
	padding: 14px 39px;
	border: none;
	cursor: pointer;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #f8fafc;
}

.cardBottomCont {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.cardBottomInfo {
	width: 155px;
}

.cardBottomPosted {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #a6a6a6;
}

.cardBottomExpire {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #4d4d4d;
  margin-top: 5px;
}

.cardBottomC2a {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 264px;
}

.c2aIcon {
	padding: 10px 12px;
	display: inline-flex;
  align-items: center;
	gap: 6px;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #0667c3;
  cursor: pointer;
}

.activeStats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
	gap: 10px;
  margin-bottom: 50px;
}
