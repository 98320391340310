.contact {
	color: #0667c3;
	font-weight: 500;
	width: 91px;
	height: 34px;
	font-size: 14px;
	border: 1px solid #0667c3;
	border-radius: 20px;
	background: #fff;
}
.contact:hover {
	background: #0667c3;
	color: #fff;
}

.active_remJob, .active_remJob .district, .active_remJob .jobTitle{
	background : white !important;
	color : black !important;
}

.recommandModal {
	width: 90vw !important;
	height: 90vh;
	overflow: auto;
	padding: 0 !important;
	background: transparent !important;
}

.recommandModal::-webkit-scrollbar {
	width: 10px;
	border-radius: 10px;
}

.recommandModal::-webkit-scrollbar-thumb {
	background: linear-gradient(
		109.78deg,
		rgb(255, 207, 32) -15.8%,
		rgb(253, 128, 18) 111.31%
	);
	border-radius: 10px;
}

.scroller::-webkit-scrollbar {
	width: 10px;
	border-radius: 10px;
	margin: 2px;
}

.optionsCandiActive {
	background: #ECFEF8 !important;
	border: 1px solid #47BC96 !important;
	color: #2C9573 !important;
}


.scroller::-webkit-scrollbar-thumb {
	background: linear-gradient(
		109.78deg,
		rgb(255, 207, 32) -15.8%,
		rgb(253, 128, 18) 111.31%
	);
	border-radius: 10px;
}

.recommendedJobsCard:hover {
	background: #edf5ff !important;
	border: 2px solid #c7d5e7;
	box-shadow: 0px 0px 2px rgba(98, 95, 130, 0.08), 0px 6px 11px #e7f0fc;
	border-radius: 12px;
}

.contactBtn:hover {
	background: #0667c3 !important;
	color: #fff !important;
}

.stateDD .css-1hb7zxy-IndicatorsContainer {
	display: none !important;
}

.stateDD > * {
	border: none !important;
	color: #757575;
	font-weight: 400;
	outline: none !important;
}

.stateDD .css-1pahdxg-control:focus {
	border: none;
	box-shadow: none !important;
}

.stateDD .css-1pahdxg-control {
	border: none;
	box-shadow: none !important;
}

.stateDD .css-g1d714-ValueContainer {
	padding-left: 0;
}

.react-multi-carousel-track {
	gap: 20px;
}

.proficient {
	color: #808080;
}

@media (max-width: 400px) {
    .proficient {
		font-size: 15px;
    }
}