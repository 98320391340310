.attachmentHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px;
	margin: 50px auto;
}

.attachmentHeading {
	font-size: 23px;
	line-height: 28px;
	/* identical to box height, or 122% */

	letter-spacing: -0.03em;

	color: #59534d;
}

.uploadBTN {
	cursor: pointer;
	border: 1px solid #0667c3;
	box-sizing: border-box;
	border-radius: 50px;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height, or 121% */

	text-align: center;
display: block;
margin: auto;
width: 200px;
margin-top: 20px;

	color: #0667c3;
	padding: 12px 25px;
}

.tableCont {
	margin: auto;
	width: 1000px;
	max-width: 95vw;
	overflow-x: auto;
}

.tableLayout {
	box-sizing: border-box;
	min-width: 938px;
	padding: 8px 16px;
	display: grid;
	grid-template-columns: 1fr 1fr 2fr;
}

.tableHead {
	font-size: 14px;
	line-height: 19px;
	/* identical to box height */

	text-transform: uppercase;

	color: #8c96a6;
}

.docTableCont {
	margin-top: 13px;
	box-sizing: border-box;
	background: #e7ecf3;
	border: 1px solid #b3bac5;
	border-radius: 6px;
}

.docRow {
	background-color: #fff;
	margin-bottom: 1px;
	width: 100%;
	padding: 16px;
}

.docRowFirst {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}

.docRowLast {
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
}

.docName {
	display: inline-flex;
	align-items: center;
	gap: 16px;
	font-size: 14px;
	line-height: 19px;
	/* identical to box height */

	color: #282d35;
}

.docName > img {
	cursor: pointer;
}

.docAction {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.visibilityChanger {
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	gap: 16px;
}

.IconCont {
	display: inline-flex;
	align-items: center;
	gap: 20px;
}

.IconCont > img {
	cursor: pointer;
}

.modalCont {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	outline: none;
	box-sizing: border-box;
	box-shadow: 0px 0px 4px rgba(122, 102, 92, 0.26),
		0px 4px 22px rgba(122, 102, 92, 0.22);
	border-radius: 8px;
	padding: 1rem;
	overflow: auto;
	max-height: 80vh;
	width: 625px;
	max-width: 95vw;
}

.modalHeadinFileUp {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 30px;
	flex-wrap: wrap;
	margin-bottom: 40px;
}

.fileUPC2a {
	border: none;
	background: linear-gradient(109.78deg, #ffcf20 -15.8%, #fd8012 111.31%);
	border-radius: 43px;
	padding: 10px 20px;
	box-sizing: border-box;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height, or 121% */

	text-align: center;

	color: #ffffff;
}

.FileInputDesign {
	border: 2px dashed #8da1ad;
	box-sizing: border-box;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 24px;
	position: relative;
	z-index: 1;
}

#fileUp {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	width: 100%;
	overflow: hidden;
	position: absolute;
	z-index: 2;
	box-sizing: border-box;
}
