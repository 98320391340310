.jobAlertCont {
	width: 95%;
	max-width: 1110px;
	margin: 50px auto;
	margin-top: 150px;
}

.JobAlertCard {
	padding: 20px;
	border: 1px solid #d4d4d4;
	box-sizing: border-box;
	border-radius: 11px;
	gap: 24px;
	width: 516px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.jobAlertContCards {
	display: inline-flex;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	gap: 24px;
}

.alertTitle {
	font-weight: 500;
	font-size: 19px;
	line-height: 23px;
	/* identical to box height, or 121% */
	letter-spacing: -0.04em;
	/* Black Neutral/900 */
	color: #0b0b0b;
	margin-bottom: 17px;
}

.alertCity {
	font-size: 14px;
	line-height: 21px;
	/* identical to box height, or 151% */
	/* Black Neutral/500 */
	color: #636363;
}

.alertSecondCol {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
  height: 100%;
}
