.badgeCont {
	padding: 4px 10px;
	display: inline-flex;
	gap: 8px;
	border: 1px solid #b9c5de;
	box-sizing: border-box;
	border-radius: 48px;
	align-items: center;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	color: #526182;
}

.badgeContGrid {
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
}

.h2aC2a {
	background: linear-gradient(109.78deg, #ffcf20 -15.8%, #fd8012 111.31%);
	border-radius: 43px;
	padding: 12px 18px;
	font-weight: 600;
	font-size: 16px;
	line-height: 17px;
	/* identical to box height, or 106% */
	text-align: center;
	color: #ffffff;
	border: none;
	display: block;
	margin: auto;
	margin-top: 50px;
	cursor: pointer;
}

.statsCont {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10rem;
	flex-wrap: wrap;
	margin: 100px auto;
}

.stat {
	width: 165px;
}

.stat > h1 {
	margin-bottom: 13px;
	font-weight: 600;
	font-size: 48px;
	line-height: 58px;
	/* identical to box height, or 120% */
	color: #313131;
	text-align: center;
}

.stat > p {
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */
	text-align: center;
	color: #777777;
}

.commentsCarouselCont {
	margin: 100px auto;
	background: url('./Group 2087.png');
	background-repeat: no-repeat;
	background-position: center top;
}

.carouselItem {
	padding: 2rem;
	box-sizing: border-box;
	max-width: 608px;
	margin: auto;
}

.comment {
	font-size: 16px;
	line-height: 28px;
	/* or 178% */
	text-align: center;
	margin-bottom: 70px;
	color: #636363;
}

.commentBy {
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	/* identical to box height */
	margin-bottom: 7px;
	text-align: center;
	/* Black Neutral/900 */
	color: #0b0b0b;
}

.commentLoc {
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */
	text-align: center;
	color: #151515;
}

@media screen and (max-width: 960px) {
	.statsCont {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5rem;
		flex-wrap: wrap;
		margin: 100px auto;
	}
	.mobCenter {
		justify-content: center !important;
	}
}
