.circle {
	position: relative;
	width: 100px;
	height: 100px;
}

.circle > img {
	border-radius: 50%;
	width: 90px;
	height: 90px;
	object-fit: cover;
}

.arc_q {
	position: absolute;
	top: 0;
	left: 0;
	width: 90px;
	height: 90px;
	border-radius: 50%;
	border: 5px solid;
	border-color: transparent #22abb2 transparent transparent;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
}

.arc_cover {
	position: absolute;
	top: 0;
	left: 0;
	width: 60px;
	height: 60px;
	background-color: transparent;
}

.profile {
	width: 95%;
	margin: auto;
	max-width: 1440px;
}

.profileCont {
	display: inline-flex;
	gap: 20px;
	width: 100%;
	margin-top: 100px;
}

.profileLeft {
	width: 100%;
}

.profileRight {
	width: 30%;
}

.profileRightSticky {
	width: 100%;
	position: sticky;
	top: 150px;
	background: #ffffff;
	border: 1px solid #d7dbe0;
	box-sizing: border-box;
	box-shadow: 0px 0px 1px rgba(118, 129, 146, 0.69),
		0px 5px 7px rgba(164, 186, 207, 0.19);
	border-radius: 8px;
	padding: 20px;
}

.stickyTop {
	width: 100%;
	margin-bottom: 50px;
	box-sizing: border-box;
	display: inline-flex;
	gap: 10px;
	align-items: flex-start;
}

.profileImgRight {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	object-fit: cover;
}

.stickyRightRight {
	width: 100%;
}

.__top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.__topLeft {
	font-size: 14px;
	line-height: 17px;
	text-transform: uppercase;
	color: #18382d;
}

.__topRight {
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	color: #0667c3;
	cursor: pointer;
}

.trpC2a {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #e1882d;
	cursor: pointer;
}

.profileCard {
	width: 100%;
	box-sizing: border-box;
	padding: 24px;
	box-shadow: 0px 0px 1px rgba(118, 129, 146, 0.69),
		0px 5px 7px rgba(164, 186, 207, 0.19);
	border-radius: 8px 8px 0px 0px;
}

.profileCardSec {
	width: 100%;
	box-sizing: border-box;
	padding: 24px;
	box-shadow: 0px 0px 1px rgba(118, 129, 146, 0.69),
		0px 5px 7px rgba(164, 186, 207, 0.19);
	border-radius: 0px 0px 8px 8px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.opportunityTitle {
	font-weight: 500;
	font-size: 19px;
	line-height: 23px;
	letter-spacing: -0.04em;
	color: #0b0b0b;
	margin-bottom: 8px;
}

.opportunitySubTitle {
	font-size: 14px;
	line-height: 17px;
	color: #474747;
	margin-bottom: 20px;
}

.tagBoldText {
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	color: #33334e;
}

.editOpportunityBtn {
	border: 1px solid #0667c3;
	box-sizing: border-box;
	border-radius: 50px;
	padding: 8px 12px;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: #0667c3;
	cursor: pointer;
	max-width: 122px;
	max-height: 34px;
}

.profileName {
	display: inline-flex;
	gap: 24px;
	margin-bottom: 40px;
	width: 100%;
}

.candidateName {
	font-weight: 600;
	font-size: 23px;
	line-height: 28px;
	letter-spacing: -0.03em;
	color: #373737;
}

.candidateLocation {
	font-size: 14px;
	line-height: 17px;
	color: #808080;
}

.profileCompletion {
	font-size: 14px;
	line-height: 17px;
	text-transform: uppercase;
	color: #0667c3;
	margin-top: 37px;
}

.profileTagCont {
	display: inline-flex;
	gap: 8px;
	flex-wrap: wrap;
	width: 100%;
	max-width: 525px;
}

.profileTag {
	padding: 9.2px 12px;
	border: 1px solid #e4e8f1;
	box-sizing: border-box;
	border-radius: 50px;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.02em;
	color: #33334e;
	display: inline-flex;
	gap: 10px;
	min-width: 8rem;
	width: fit-content;
	justify-content: center;
}

.vidResumeVecCont {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 40px;
	flex-wrap: wrap;
}

.vidResCont {
	width: 293px;
}

.vidResTitle {
	font-weight: 500;
	font-size: 19px;
	line-height: 23px;
	letter-spacing: -0.04em;
	color: #0b0b0b;
}

.vidResDesc {
	font-size: 14px;
	line-height: 17px;
	color: #808080;
	margin: 8px 0;
}

.vidResPoint {
	font-size: 14px;
	line-height: 20px;
	color: #808080;
	padding-left: 20px;
}

.vidResC2a {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	color: #ffffff;
	background: #0667c3;
	border-radius: 50px;
	padding: 12px 18px;
	border: none;
	margin: auto;
	cursor: pointer;
	display: block;
	margin-top: 50px;
}

.dataCont {
	display: flex;
	flex-wrap: wrap;
	position: relative;
}

.dataContLeft {
	width: 100%;
	max-width: 222px;
	font-size: 14px;
	line-height: 17px;
	text-transform: uppercase;
	color: #636363;
}

.dataContRight {
	/* width: 100%;
	max-width: 450px; */
	flex-grow: 1;
}

.dataContRightTitle {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #0b0b0b;
}

.dataContRightSubTitle {
	font-size: 14px;
	line-height: 17px;
	color: #636363;
	margin: 8px 0px;
}

.dataContRightData {
	font-size: 14px;
	line-height: 22px;
	color: #636363;
	margin-top: 15px;
}

.dataContC2a {
	margin: 20px 0;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #f28612;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
}

.btnCont {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	position: absolute;
	right: 0px;
	top: 0px;
}

.btnCont > img {
	cursor: pointer;
}

.crop-container{
	position : absolute;
	top : 50px;
	right: 0;
	/* display: flex;
	justify-content: space-evenly; */
	background-color: rgba(0, 0, 0, 0.69);
	width: 100%;
	max-height: 100vh;
	z-index: 500;
	overflow-x: auto;
	scroll-behavior: auto;
	padding: 5vh 0;
}

.cropButton{
	position: relative;
}
.cancelButton{
	background-color: #a79b9b;
	color: white;
}

@media screen and (max-width: 960px) {
	.profileRight {
		display: none;
	}
	.profileCard {
		padding: 10px;
	}
	.profileCardSec {
		padding: 10px;
		display: block;
	}
	.circle {
		width: 80px;
		height: 80px;
	}
	.circle > img {
		border-radius: 50%;
		width: 70px;
		height: 70px;
		object-fit: cover;
	}
	.arc_q {
		width: 70px;
		height: 70px;
	}
	.profileName {
		gap: 10px;
		margin-bottom: 20px;
	}
	.candidateName {
		font-size: 19px;
		line-height: 28px;
	}
	.candidateLocation {
		font-size: 14px;
		line-height: 17px;
	}
	.profileCompletion {
		margin-top: 17px;
	}
	.opportunityTitle {
		font-size: 16px;
	}
	.dataCont {
		display: block;
	}
	.dataContLeft {
		max-width: unset;
	}
	.dataContRight {
		max-width: unset;
	}
	.editOpportunityBtn {
		margin-top: 1rem;
	}
}

.modalCont {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	outline: none;
	box-sizing: border-box;
	box-shadow: 0px 0px 4px rgba(122, 102, 92, 0.26),
		0px 4px 22px rgba(122, 102, 92, 0.22);
	border-radius: 8px;
	overflow: auto;
	max-height: 80vh;
	padding: 1rem;
}

.btnProfileModalCont {
	padding: 20px 0;
	display: inline-flex;
	align-items: center;
	justify-content: flex-end;
	gap: 16px;
	width: 100%;
	margin-left: -2.5em;
}

.btnContCancelBtn {
	border: 1px solid #808080;
	box-sizing: border-box;
	border-radius: 40px;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	letter-spacing: -0.02em;
	color: #808080;
	padding: 12px 20px;
	cursor: pointer;
	background-color: #fff;
}

.btnContCancelBtn2 {
	border: none;
	box-sizing: border-box;
	border-radius: 40px;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	/* identical to box height, or 122% */
	text-align: center;
	/* Primary Color/500 */
	color: #F47B08;
	padding: 12px 20px;
	cursor: pointer;
	background-color: #fff;
}

.btnContSaveBtn {
	border: none;
	background: linear-gradient(109.78deg, #ffcf20 -15.8%, #fd8012 111.31%);
	border-radius: 40px;
	box-sizing: border-box;
	border-radius: 40px;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	letter-spacing: -0.02em;
	color: #fff;
	padding: 13px 30px;
	cursor: pointer;
}

.MUIRichTextEditor-root-1 {
	height: 150px;
}
.descInput{
	width: 100%;
	margin: 24px 0;
}

@media screen and (max-width: 976px) {
	.modalCont {
		/* max-width: fit-content; */
	}
	.descInput{
		width: 400px;
	}
	.btnProfileModalCont{
		justify-content: flex-start;
		margin-left: 2.5em;
	}
	
	
}

.optionCont2 {
	display: inline-flex;
	gap: 16px;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.option2 {
	max-width: 400px;
	width: 100%;
	border: 1px solid #c4d3e0;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 10px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 15px;
}

.optionTitle {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #33334e;
	margin-bottom: 4px;
	
}

.optionSubtext {
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.02em;
	color: #33334e;
	
}
