.nav-links {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;

	position: static;
	height: 32px;
	left: 90px;
	top: 4px;

	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;

	letter-spacing: -0.02em;

	color: #4C5464;

	flex: none;
	order: 0;
	flex-grow: 0;
	margin: 0px 10px;
}
.sign-up {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;

	position: static;
	height: 32px;
	left: 90px;
	top: 4px;
	font-size: 14px;

	background: #FE9015;
	border-radius: 102px;

	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 10px;
}
