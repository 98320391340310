.statBadgeCont {
	display: inline-flex;
	align-items: center;
	gap: 25px;
	max-width: 410px;
}

.textBadgeTitle {
	font-weight: bold;
	font-size: 16px;
	line-height: 163.4%;
	/* or 26px */
	letter-spacing: -0.02em;
	margin-bottom: 8px;
	color: #424242;
}

.textBadgeDesc {
	font-size: 16px;
	line-height: 163.4%;
	/* or 26px */
	letter-spacing: -0.02em;
	color: #242424;
}

.contactUs {
    background: #EDF1F8;

}

.subscription {
    background: #F1F4F9;
    border-radius: 8px;
}


.bubble {
    border: 1px solid #BEBEBE;
    margin-left: 8px;
    text-align: center;
    border-radius: 1.15rem;
    line-height: 1.25;
    max-width: fit-content;
    padding: 0.2rem .6rem;
    position: relative;
    word-wrap: break-word;

}

.email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 0.9rem;
    margin: 10px 10px 10px 10px;
    padding: 5px 0px 5px 0px;


    border: 1px solid #BEBEBE;
    border-radius: 25px;
    background-color: white;
}

.subscribeButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0px 10px 0px;

    background: linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%), #FFFFFF;
    border-radius: 25px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    margin: 20px 10px;
}

.inputField {
    margin-top: 0%;
    background: white;
}

.card {
    padding-bottom: 1rem;
    background: #FFFFFF;
    border-radius: 10px;

}

.quote {
    width: 104px;
    height: 92px;
    margin-left: 25px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 221px;
    line-height: 229px;
    letter-spacing: -0.02em;

    color: #1E1E1E;
}

.carouselItem {
	padding: 1rem;
	max-width: 608px;
}

.comment {
	font-size: 16px;
	line-height: 28px;
	text-align: center;
	color: #636363;
}

.commentBy {
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	/* Black Neutral/900 */
	color: #0b0b0b;
}

.commentLoc {
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */
	text-align: center;
	color: #151515;
}

.carousel {
    height: fit-content;
}

.contactForm {
    padding-left: 5%;
    align-content: center;
    justify-content: center;
}

@media (max-width: 400px) {
    .carousel {
        height: 30rem;
    }
    .contactForm {
        padding-left: 0;
    }
}

