.emplLpHero {
	background: url(./Group\ 2127.png);
	background-position: bottom center;
	background-size: contain;
	background-color: #b6feed;
	background-repeat: no-repeat;
	width: 100vw;
	max-width: 100%;
	height: 900px;
	max-height: 100vh;
	position: relative;
	margin-bottom: 360px;
}

.bgcFilter {
	mix-blend-mode: multiply;
	width: 100vw;
	max-width: 100%;
	height: 900px;
	max-height: 100vh;
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.6;
	background-image: url(./image\ 287.png);
}

.heroHeading {
	font-weight: bold;
	font-size: 44px;
	line-height: 109%;
	letter-spacing: -0.03em;
	text-align: center;
	color: #141a21;
	position: relative;
	left: 50vw;
	transform: translate(-50%, -50%);
	top: 20vh;
	z-index: 2;
}

.heroSubHeading {
	font-size: 16px;
	line-height: 144%;
	width: 595px;
	max-width: 95vw;
	text-align: center;
	color: #585858;
	position: relative;
	left: 50vw;
	transform: translate(-50%, -50%);
	top: 25vh;
	z-index: 2;
}

.heroContentDiv {
	min-height: 328px;
	background: #ffffff;
	border: 1px solid #dae4e2;
	box-sizing: border-box;
	box-shadow: 0px 16px 34px #f5f5f5;
	border-radius: 20px;
	width: 75vw;
	max-width: 95vw;
	position: relative;
	left: 50vw;
	transform: translate(-50%, -50%);
	top: calc(100% - 50px);
	box-sizing: border-box;
	padding: 4rem;
	z-index: 2;
}

.emplLpfeaturesCont {
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 5rem;
	flex-wrap: wrap;
}

.empLpFeature {
	display: flex;
	align-items: flex-start;
	gap: 14px;
}

.empLpFeatureHeading {
	font-weight: bold;
	font-size: 22px;
	line-height: 24px;
	/* identical to box height, or 109% */
	letter-spacing: -0.03em;
	margin-bottom: 10px;
	color: #141a21;
}

.empLpFeatureSubHeading {
	font-size: 14px;
	line-height: 19px;
	/* or 136% */
	letter-spacing: -0.01em;
	width: 212px;
	color: #585858;
}

.c2aHead {
	margin-top: 60px;
	margin-bottom: 25px;
	font-weight: 500;
	font-size: 22px;
	line-height: 26px;
	/* identical to box height, or 118% */
	text-align: center;
	letter-spacing: -0.06em;
	color: #141a21;
}

.c2aBtn {
	display: block;
	margin: auto;
	border: none;
	padding: 18px 59px;
	font-weight: 500;
	font-size: 22px;
	line-height: 16px;
	/* identical to box height, or 73% */
	letter-spacing: -0.05em;
	background: linear-gradient(95.38deg, #ff820e -4.47%, #f34d0e 104.97%);
	border-radius: 102px;
	color: #ffffff;
	cursor: pointer;
}

.headLp {
	font-weight: bold;
	font-size: 28px;
	line-height: 36px;
	/* identical to box height, or 129% */
	text-align: center;
	letter-spacing: -0.04em;
	color: #141a21;
	margin-bottom: 24px;
}

.DescLp {
	width: 631px;
	max-width: 95vw;
	font-size: 14px;
	line-height: 20px;
	/* or 143% */
	margin: auto;
	text-align: center;
	letter-spacing: -0.04em;
	color: #141a21;
}

.paddLeft {
	padding: 12rem 6rem !important;
	padding-bottom: 5rem !important;
}

.paddRight {
	padding: 4rem !important;
}

@media screen and (max-width: 960px) {
	.paddLeft {
		padding: 2rem !important;
	}
	.paddRight {
		padding: 2rem !important;
	}
	.heroHeading {
		font-size: 22px;
	}
	.heroContentDiv {
	top: calc(100% - 150px);
	padding: 2rem;
}
}
