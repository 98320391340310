.activityLogBkBtn {
  background-color: transparent;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  padding: 8px 12px;
  border: 1px solid #033462;
  box-sizing: border-box;
  border-radius: 50px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */
  color: #033462;
  margin-bottom: 50px;
}

.tableNameName {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #414853;
}

.tableNamePosition {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #8C96A6;
}
