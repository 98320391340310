.searchBar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 10px 10px 20px;


    border: 1px solid #BEBEBE;
    border-radius: 25px;
}

.contactUs {
    background: #EDF1F8;
    width: 100%;
    padding: 5rem 0rem 4rem 5rem;
}

.subscription {
    background: #F1F4F9;
    border-radius: 8px;
}


.bubble {
    border: 1px solid #BEBEBE;
    margin-left: 8px;
    text-align: center;
    border-radius: 1.15rem;
    line-height: 1.25;
    max-width: fit-content;
    padding: 0.2rem .6rem;
    position: relative;
    word-wrap: break-word;

}

.email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 0.9rem;
    margin: 10px 10px 10px 10px;
    padding: 5px 0px 5px 0px;


    border: 1px solid #BEBEBE;
    border-radius: 25px;
    background-color: white;
}

.subscribeButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0px 10px 0px;
    

    background: linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%), #FFFFFF;
    border-radius: 25px;
    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    margin: 20px 10px;
}

.ad {
    position: static;
    height: 600px;
    left: 0px;
    top: 0px;

    background: #F1F4F9;
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 30px 0px;
}

.contactForm {
    padding-left: 5%;
    align-content: center;
    justify-content: center;
}

@media (max-width: 400px) {
    .carousel {
        height: 30rem;
    }
    .contactForm {
        padding-left: 0;
    }
}
