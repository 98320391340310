.separator {
	display: flex;
	align-items: center;
	text-align: center;
	color: #9d9d9d;
	font-size: 0.9em;
	font-weight: 500;
	font-family: 'Montserrat';
}

.separator::before,
.separator::after {
	content: '';
	flex: 1;
	border-bottom: 1px solid #c4c3c3;
}

.separator:not(:empty)::before {
	margin-right: 0.25em;
}

.separator:not(:empty)::after {
	margin-left: 0.25em;
}

.text {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
}

.label {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	font-weight: 500;
	color: #515151;
	margin: 5px 0;
	line-height: 17px;
}

.loginModalHead {
	font-weight: 600;
	font-size: 26px;
	line-height: 154%;
	text-align: center;
	letter-spacing: -0.03em;
	color: #2f2e41;
	margin-top: 20px;
}

.loginModalSubHead {
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	/* identical to box height, or 137% */
	margin-bottom: 40px;
	letter-spacing: -0.02em;
	color: rgba(47, 46, 65, 0.7);
  text-align: center;
}

.loginModalBtn {
	padding: 12px 100px;
	display: block;
	margin: auto;
	background: #0881f4;
	border-radius: 43px;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height, or 121% */
	text-align: center;
	/* White */
	color: #ffffff;
  border: none;
}
