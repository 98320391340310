.profileTag2 {
	padding: 7.2px 10px;
	border: 1px solid #e4e8f1;
	box-sizing: border-box;
	border-radius: 50px;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.02em;
	color: #33334e;
	display: inline-flex;
	/* gap: 10px; */
	/* min-width: 6rem; */
	width: fit-content;
	justify-content: center; 


}
.tagBoldText2 {
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	color: #33334e;
}

.requests-main-div{
    max-width: 90vw;
    margin: 0 auto;
}
.single-request{
    border: 1px solid #CED1D6;
    border-radius: 10px;
    max-width: 70%;
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
    /* align-items: flex-end; */
    margin: 0 0;
    padding: 20px 30px;
    margin-bottom: 24px;
    min-height: 191px !important; 


}
.request-text{
    width: 80%;
}

.rating-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.rating{
    margin-bottom: 25%;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
}

.accept-div{
    margin: 0 !important;
}

.card {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 25px;
    padding: 0px;
    border: none;
 
}
.card-content{
    z-index:-1;
    box-shadow: 0 4px 2px -30px gray;
}
.card-content:after {
    z-index:-1;
    transform:scale(.9); 
    box-shadow: 0 4px 2px -30px gray;
}


.all-requests{
    max-width: 80vw;
    margin: 0px auto 161px;
    background-color: white;
    padding: 2em 4em;
    box-shadow: rgb(0 0 0 / 3%) 0px 0px 1px 1px, rgb(0 0 0 / 11%) 0px 2px 4px;
    border-radius: 8px;

}
.pendingRequests{
    border: 1px solid #CED1D6;
    border-radius: 10px;
    max-width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px 30px;
    margin-bottom: 24px;
    min-height : 100px !important;
}
@media screen and (max-width: 1200px) {
    .single-request{
        max-width: 90%;
    }
    
}


/* and (min-width: 0px) */
    
@media screen and (max-width: 900px) {
    .single-request{
        max-width: 100%;
        /* justify-content: start; */
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        
    }
   
    .acceptBtn{
        margin-left: 25% !important;
        /* display: block; */
        width: 50%;
        /* margin:0 auto; */
    }
    .accept-div{
        margin-top: 40px;
        margin-left: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
    }

    .request-text{
        width: 100%;
    }
    .rating-div{
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }
    .rating{
        margin-bottom: 40px;
    }
}
@media screen and (max-width: 820px) {
    .card {
        padding: 10px
    }
   
}
@media screen and (max-width:768px) {
  
    .accept-div{
        display: flex;
        flex-direction: column !important;

    }
    .requestButton{
        margin: 5px  0px !important;

    }
    .all-requests{
        max-width: 90vw;
        padding: 2em 1em;
    }
    
}
@media screen and (max-width: 576px) {
 
    .single-request{
        width: 95%;
        margin: 0 auto 24px;
     
    }
    .all-requests{
        max-width: 100vw;
        padding: 2em 1em;
    }

    
}
