.jobsAvailable {
	font-size: 14px;
	line-height: 19px;
	color: #474747;
	align-self: center;
	justify-self: flex-end;
}

.recieveUpdatesCont {
	background: #ffffff;
	box-shadow: 0px 0px 6px rgba(186, 192, 202, 0.2),
		2px 3px 15px rgba(204, 216, 219, 0.36);
	border-radius: 8px;
	padding: 1em;
	margin-bottom: 2em;
	margin-top: 8px;
	max-width: 95vw;
}

.recieveUpdatesHeading {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
}

.recieveUpdatesSubHeading {
	font-size: 14px;
	line-height: 19px;
	color: #636363;
	margin-bottom: 1.5em;
}

.recieveUpdateInput {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.recieveUpdateInput > input {
	width: 309px;
	height: 36px;
	box-sizing: border-box;
	padding: 8px 11px;
	background: #ffffff;
	border: 1px solid #cbd5e1;
	box-sizing: border-box;
	border-radius: 4px;
	font-size: 14px;
	line-height: 19px;
	color: #1e293b;
}

.missingC2A {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	letter-spacing: -0.02em;
	color: #ffffff;
	padding: 12px 20px;
	background: #1fb0a7;
	border: 1px solid #1fb0a7;
	box-sizing: border-box;
	border: none;
	border-radius: 50px;
	cursor: pointer;
}

.modalCont {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	outline: none;
	box-sizing: border-box;
	box-shadow: 0px 0px 4px rgba(122, 102, 92, 0.26),
		0px 4px 22px rgba(122, 102, 92, 0.22);
	border-radius: 12px;
	padding: 10px;
	overflow: auto;
	max-height: 80vh;
	width: 1101px;
	max-width: 95vw;
	padding: 1rem;
}

.ModalHeading {
	font-weight: 600;
	font-size: 28px;
	line-height: 120%;
	letter-spacing: -0.03em;
	color: #0b0b0b;
	margin-bottom: 20px;
	text-align: center;
}

.ModalDescription {
	font-size: 19px;
	line-height: 120%;
	text-align: center;
	letter-spacing: -0.04em;
	color: #2a2a2a;
	text-align: center;
	max-width: 508px;
	margin: auto;
	margin-bottom: 20px;
}

.modalClose {
	cursor: pointer;
	position: absolute;
	top: -25px;
	left: 10px;
}

.chevronCont {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.chevronCont > img {
	cursor: pointer;
}

.modalOF {
	width: 100%;
	overflow-x: auto;
	scroll-behavior: smooth;
}

.modalOF::-webkit-scrollbar {
	display: none;
}

.carouselCont {
	padding: 10px 50px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 30px;
}

.carouselCardCont {
	background: #ffffff;
	box-shadow: 0px 0px 3px #aabab7, 0px 7px 17px rgba(96, 108, 106, 0.21);
	border-radius: 10px;
	width: 420px;
	max-width: 90vw;
	box-sizing: border-box;
	padding: 20px 25px;
	font-size: 14px;
	line-height: 19px;
	color: #636363;
}

.cardProfile {
	display: inline-flex;
	align-items: center;
	gap: 20px;
	margin-bottom: 20px;
}

.cardProfile > img {
	width: 88px;
	height: 88px;
	border-radius: 50%;
}

.profileName {
	font-weight: 600;
	font-size: 23px;
	line-height: 28px;
	/* identical to box height, or 122% */
	letter-spacing: -0.03em;
	color: #373737;
}

.profileDesc {
	font-size: 16px;
	line-height: 24px;
	/* or 150% */
	color: #808080;
}

.cardC2a {
	border: none;
	display: inline-flex;
	align-items: center;
	gap: 6px;
	cursor: pointer;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #f8fafc;
	box-sizing: border-box;
	padding: 8px 12px;
	background: linear-gradient(109.78deg, #ffcf20 -15.8%, #fd8012 111.31%);
	border-radius: 50px;
}

.workExp_subdiv{
	color : #888282;
	font-size: 15px;
}

.modal_active {
	border: 1px solid orange !important;
	box-shadow: gray;
}

@media screen and (max-width: 960px) {
	.deskOnly {
		display: none !important;
	}
	.ModalHeading {
		font-size: 18px;
	}
}
